var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{attrs:{"flat":""}},[_c('v-text-field',{style:({
      width: '100%',
      borderBottomRightRadius: '0px',
      borderBottomLeftRadius: '0px',
    }),attrs:{"solo":"","flat":"","hide-details":"","label":"Buscar produto","color":"#0f172a","append-icon":"search","dark":"","background-color":this.$vuetify.theme.dark ? '#333' : 'primary lighten-1'},on:{"input":_vm.filtraProds}}),_c('v-card-text',{style:({
      background: _vm.$vuetify.theme.dark ? '#444' : 'var(--v-primary-lighten2)',
      height: '40px',
    })},[_c('v-row',_vm._l((_vm.header),function(cabecalho,i){return _c('v-col',{key:i,style:({ padding: '6px' }),attrs:{"cols":cabecalho.col}},[_c('span',{style:({ fontWeight: 'bold', color: 'white' })},[_vm._v(_vm._s(cabecalho.id))])])}),1)],1),_c('v-virtual-scroll',{style:({
      border: _vm.$vuetify.theme.dark ? '1px solid #555' : '1px solid #ddd',
    }),attrs:{"bench":1,"items":_vm.lista,"height":"600","item-height":"50"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
    var item = ref.item;
    var index = ref.index;
return [_c('v-card',{staticClass:"cardVirtual transicaoCard d-flex flex-row justify-start",style:({
          overflowX: 'hidden',
          overflowY: 'hidden',
          background: 'transparent',
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'center',
          alignItems: 'center',
          borderBottom: _vm.$vuetify.theme.dark
            ? '1px solid #555'
            : '1px solid #ddd',
        }),attrs:{"flat":"","height":"50","rounded":"0"},on:{"click":function($event){return _vm.$emit('prod', index)}}},[_c('v-row',[_vm._l((_vm.itensCard),function(campo,index){return _c('v-col',{key:index,attrs:{"cols":campo.col}},[_c('div',{class:("" + (index == 0 ? 'ml-4' : ''))},[_c('small',[_vm._v(_vm._s(item[campo.id]))])])])}),_c('v-col',{attrs:{"cols":"6"}},[_c('v-tooltip',{attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var on = ref.on;
        var attrs = ref.attrs;
return [_c('small',_vm._g(_vm._b({style:({ fontWeight: 'bold' })},'small',attrs,false),on),[_vm._v(" "+_vm._s(item.prod.substring(0, 25))+_vm._s(item.prod.length > 25 ? "..." : "")+" ")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(item.prod))])])],1)],2)],1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }