<template>
  <v-card flat>
    <v-text-field
      solo
      flat
      hide-details
      label="Buscar produto"
      :style="{
        width: '100%',
        borderBottomRightRadius: '0px',
        borderBottomLeftRadius: '0px',
      }"
      color="#0f172a"
      append-icon="search"
      dark
      :background-color="
        this.$vuetify.theme.dark ? '#333' : 'primary lighten-1'
      "
      @input="filtraProds"
    />

    <v-card-text
      :style="{
        background: $vuetify.theme.dark ? '#444' : 'var(--v-primary-lighten2)',
        height: '40px',
      }"
    >
      <v-row>
        <v-col
          v-for="(cabecalho, i) in header"
          :key="i"
          :cols="cabecalho.col"
          :style="{ padding: '6px' }"
        >
          <span :style="{ fontWeight: 'bold', color: 'white' }">{{
            cabecalho.id
          }}</span>
        </v-col>
      </v-row>
    </v-card-text>
    <v-virtual-scroll
      :bench="1"
      :items="lista"
      height="600"
      item-height="50"
      :style="{
        border: $vuetify.theme.dark ? '1px solid #555' : '1px solid #ddd',
      }"
    >
      <template v-slot:default="{ item, index }">
        <v-card
          flat
          height="50"
          rounded="0"
          class="cardVirtual transicaoCard d-flex flex-row justify-start"
          :style="{
            overflowX: 'hidden',
            overflowY: 'hidden',
            background: 'transparent',
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
            alignItems: 'center',
            borderBottom: $vuetify.theme.dark
              ? '1px solid #555'
              : '1px solid #ddd',
          }"
          @click="$emit('prod', index)"
        >
          <v-row>
            <v-col
              v-for="(campo, index) in itensCard"
              :key="index"
              :cols="campo.col"
            >
              <div :class="`${index == 0 ? 'ml-4' : ''}`">
                <small>{{ item[campo.id] }}</small>
              </div>
            </v-col>
            <v-col cols="6">
              <v-tooltip right>
                <template v-slot:activator="{ on, attrs }">
                  <small
                    v-bind="attrs"
                    :style="{ fontWeight: 'bold' }"
                    v-on="on"
                  >
                    {{ item.prod.substring(0, 25)
                    }}{{ item.prod.length > 25 ? "..." : "" }}
                  </small>
                </template>
                <span>{{ item.prod }}</span>
              </v-tooltip>
            </v-col>
          </v-row>
        </v-card>
      </template>
    </v-virtual-scroll>
  </v-card>
</template>

<script>
export default {
  props: {
    produtos: {
      type: [Object || Array],
      required: true,
      default: () => {
        return [];
      },
    },
  },
  data() {
    return {
      lista: [],
      backup: [],
      itensCard: [
        { id: "barras", col: 3 },
        { id: "sku", col: 3 },
      ],
      header: [
        { id: "Cód. barras", col: 3 },
        { id: "SKU", col: 3 },
        { id: "Descrição", col: 6 },
      ],
    };
  },
  async mounted() {
    await this.produtos;
    this.lista = this.produtos.dados;
    this.backup = this.produtos.dados;
  },
  methods: {
    filtraProds(input) {
      const busca = input.toUpperCase();
      const filtrado = this.backup.filter(
        (item) =>
          item.ce110_ds_prod?.toString().includes(busca) ||
          item.ce110_cd_sku?.toString().includes(busca) ||
          item.ce119_cd_barras?.toString().includes(busca),
      );
      this.lista = filtrado;
    },
  },
};
</script>

<style>
.cardVirtual {
  overflow-x: hidden;
  display: flex o !important;
  flex-direction: row !important;
  justify-content: center !important;
  align-items: center !important;
}
.transicaoCard {
  transition-property: all;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 300ms;
}
.cardVirtual:hover {
  background: #f6a76691 !important;
  border: 1px solid #fb923c !important;
}
</style>