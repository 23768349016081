<template>
  <div>
    <v-dialog
      v-model="dialog"
      width="80vw"
      persistent
    >
      <v-card>
        <div v-if="!transition">
          <v-card-title class="ml-1">
            Edição de produtos
            <span
              class="ml-2"
              :style="{ fontWeight: 'bolder' }"
            >
              {{ prods.id }} ({{ prods.dados.length }})
            </span>
            <v-spacer />
            <v-btn
              icon
              @click="fechar"
            >
              <v-icon color="primary">
                close
              </v-icon>
            </v-btn>
          </v-card-title>
          <v-card-subtitle class="ml-1">
            Modifique os dados dos produtos
          </v-card-subtitle>
        </div>

        <div v-else>
          <div class="d-flex justify-space-between items-center px-4 pt-4">
            <v-btn
              icon
              color="primary"
              @click="transition = !transition"
            >
              <v-icon> arrow_back </v-icon>
            </v-btn>
            <v-btn
              icon
              @click="dialog = !dialog"
            >
              <v-icon color="primary">
                close
              </v-icon>
            </v-btn>
          </div>
          <v-card-title> Edição de produtos {{ prods.id }} </v-card-title>
          <v-card-subtitle> Modifique os dados dos produtos</v-card-subtitle>
        </div>
        <v-row no-gutters>
          <v-col
            v-if="!transition"
            cols="12"
            :md="$vssWidth <= 1200 ? 12 : 5"
            sm="12"
            style="padding: 0"
          >
            <DialogList
              :produtos="prods"
              :style="{ margin: '0 10px 20px 20px' }"
              @prod="clickCard"
            />
          </v-col>
          <v-col
            v-if="$vssWidth > 1200"
            cols="12"
            md="7"
            sm="12"
            style="padding: 0"
          >
            <DialogForm
              :key="attForm"
              :style="{
                margin: '0 20px 20px 10px',
              }"
              :produto-selecionado="produtoSelecionado"
              @produto="salvar"
            />
          </v-col>
          <v-col
            v-if="transition"
            cols="12"
            :md="$vssWidth <= 1200 ? 12 : 7"
            sm="12"
            style="padding: 0"
          >
            <DialogForm
              :key="attForm"
              :style="{ margin: '0 20px 20px 10px' }"
              :produto-selecionado="produtoSelecionado"
              @produto="salvar"
            />
          </v-col>
        </v-row>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import DialogForm from "./DialogForm.vue";
import DialogList from "./DialogList.vue";
export default {
  components: { DialogForm, DialogList },
  model: {
    prop: "editProd",
    event: "update:editProd",
  },
  props: {
    editProd: {
      type: Boolean,
      required: true,
      default: false,
    },
    produtos: {
      type: [Object || Array],
      required: true,
      default: () => {
        return [];
      },
    },
    chave: {
      type: Boolean,
      required: true,
      default: false,
    },
  },

  data() {
    return {
      dialog: false,
      produtoSelecionado: {},
      transition: false,
      attkey: 0,
      attForm: 0,
      prods: this.produtos,
    };
  },

  watch: {

    editProd(nv) {
      this.dialog = nv;
      this.attkey++;
    },

    dialog(newvalue) {
      this.$emit("update:editProd", newvalue);
    },

    chave(val) {
      this.attForm++;
    },

  },
  methods: {

    salvar(val) {
      const produtoEditado = val;
      this.prods.dados[produtoEditado.id] = produtoEditado.prod;
      this.$emit("produto", produtoEditado);
    },

    clickCard(val) {
      this.produtoSelecionado = {
        id: val,
        prod: this.produtos.dados[val],
      };
      //-- Feito para corrigir o v-mask quando o valor é por exemplo 10.9
      //-- O v-mask conta 2 casas, deixando com 1,09
      //-- Com o this.formatCurrency corrige o problema
      this.produtoSelecionado.prod.custo = this.formatCurrency(this.produtoSelecionado.prod.custo);
      this.produtoSelecionado.prod.venda = this.formatCurrency(this.produtoSelecionado.prod.venda);
      if (this.$vssWidth <= 1200) {
        this.transition = true;
      } else {
        this.transition = false;
      }
      this.attkey++;
      this.attForm++;
    },

    fechar() {
      this.dialog = !this.dialog;
      this.produtoSelecionado = {};
      this.attkey++;
      this.attForm++;
    },

  },
};
</script>

<style>
</style>