<template>
  <v-card
    v-if="
      produto != null && produto != undefined && Object.keys(produto).length
    "
    color="transparent"
    :style="{
      border: $vuetify.theme.dark
        ? '1px solid #555 !important'
        : '1px solid #ddd !important',
      height: '688px',
      overflowY: 'auto',
      scrollBehavior: 'smooth',
    }"
    flat
  >
    <v-form
      ref="form"
      v-model="valid"
    >
      <v-col class="d-flex justify-center pl-6 pr-6">
        <v-row :key="attKey">
          <v-col
            id="importacao-produtos"
            cols="12"
          >
            <v-switch
              v-model="produto.situac"
              dense
              inset
              :label="`Situação do produto: ${
                produto.situac == 1 ? 'Ativo' : 'Inativo'
              }`"
            />
          </v-col>
          <v-col cols="12">
            <span class="dividerSpan line glow">Identificação do produto</span>
            <v-row>
              <v-col
                sm="6"
                md="3"
                cols="12"
              >
                <v-text-field
                  v-model="produto.barras"
                  dense
                  regular
                  label="Cód. barras"
                  :rules="rules.barras"
                  :value="produto.barras"
                  type="number"
                />
              </v-col>
              <v-col
                sm="6"
                md="3"
                cols="12"
              >
                <v-text-field
                  v-model="produto.baraux"
                  regular
                  dense
                  label="Cód. barras aux."
                  :rules="rules.baraux"
                  :value="produto.baraux"
                />
              </v-col>

              <v-col
                sm="6"
                md="3"
                cols="12"
              >
                <v-text-field
                  v-model="produto.sku"
                  regular
                  dense
                  label="SKU"
                  :rules="rules.sku"
                  :value="produto.sku"
                />
              </v-col>

              <v-col
                sm="6"
                md="3"
                cols="12"
              >
                <v-text-field
                  v-model="produto.ref"
                  dense
                  label="Referência"
                  :rules="rules.ref"
                  :value="produto.ref"
                />
              </v-col>

              <v-col
                sm="12"
                md="6"
                cols="12"
              >
                <v-text-field
                  v-model="produto.prod"
                  regular
                  dense
                  label="Descrição"
                  :rules="rules.desc"
                  :value="produto.prod"
                />
              </v-col>
            </v-row>
          </v-col>

          <v-col cols="12">
            <span class="dividerSpan line glow"> Organização </span>
            <v-row>
              <v-col
                sm="6"
                md="4"
                cols="12"
              >
                <v-autocomplete
                  v-model="grupo"
                  dense
                  :items="[grupo]"
                  label="Grupo"
                />
              </v-col>

              <v-col
                sm="6"
                md="4"
                cols="12"
              >
                <v-autocomplete
                  v-model="subgrupo"
                  dense
                  :items="[subgrupo]"
                  regular
                  label="Subgrupo"
                />
              </v-col>

              <v-col
                sm="6"
                md="4"
                cols="12"
              >
                <v-autocomplete
                  v-model="depto"
                  dense
                  label="Departamento"
                  :items="[depto]"
                />
              </v-col>

              <v-col
                sm="6"
                md="4"
                cols="12"
              >
                <v-autocomplete
                  v-model="secao"
                  dense
                  label="Seção"
                  :items="[secao]"
                />
              </v-col>

              <v-col
                sm="6"
                md="4"
                cols="12"
              >
                <v-autocomplete
                  v-model="colec"
                  dense
                  label="Coleção"
                  :items="[colec]"
                />
              </v-col>

              <v-col
                sm="6"
                md="4"
                cols="12"
              >
                <v-autocomplete
                  v-model="categ"
                  dense
                  regular
                  label="Categoria"
                  :items="[categ]"
                />
              </v-col>

              <v-col
                sm="6"
                md="4"
                cols="12"
              >
                <v-autocomplete
                  v-model="marca"
                  dense
                  regular
                  label="Marca (grife)"
                  :items="[marca]"
                />
              </v-col>

              <v-col
                sm="6"
                md="4"
                cols="12"
              >
                <v-autocomplete
                  v-model="produto.tp_potica"
                  dense
                  label="Tp. prod. ótica"
                  :items="tp_otico"
                  item-text="name"
                  item-value="id"
                />
              </v-col>

              <v-col
                sm="3"
                md="2"
                cols="12"
              >
                <v-autocomplete
                  v-model="produto.fator"
                  dense
                  regular
                  label="Sigla do fator"
                  :items="[fator]"
                />
              </v-col>

              <v-col
                sm="3"
                md="2"
                cols="12"
              >
                <v-autocomplete
                  v-model="produto.mf"
                  dense
                  regular
                  label="MF"
                  :items="[mf]"
                />
              </v-col>  
            </v-row>
          </v-col>

          <v-col cols="12">
            <span class="dividerSpan line glow">Fiscal</span>
            <v-row>
              <v-col
                sm="6"
                md="4"
                cols="12"
              >
                <v-autocomplete
                  v-model="produto.unidade"
                  dense
                  label="Unidade"
                  :items="unidade"
                  item-text="nf722_cd_uncom"
                  item-value="nf722_cd_uncom"
                />
              </v-col>

              <v-col
                sm="6"
                md="2"
                cols="12"
              >
                <v-autocomplete
                  v-model="produto.origem"
                  dense
                  label="Origem"
                  :items="[produto.origem]"
                />
              </v-col>

              <v-col
                sm="10"
                md="4"
                cols="12"
              >
                <v-autocomplete
                  v-model="produto.ncm"
                  dense
                  label="NCM"
                  :items="ncm"
                  item-text="ce311_cd_ncm"
                  item-value="ce311_cd_ncm"                  
                />
              </v-col>
              <v-col
                sm="2"
                md="2"
                cols="12"
              >
                <v-text-field
                  v-model="produto.exncm"
                  dense
                  regular
                  label="Ex. NCM"
                  disabled
                  :value="produto.exncm"
                />
              </v-col>
            </v-row>
          </v-col>

          <v-col cols="12">
            <span class="dividerSpan line glow">Preço</span>
            <v-row>
              <v-col
                sm="6"
                :md="$vssWidth <= 1200 ? 6 : 3"
                cols="12"
              >
                <v-text-field
                  v-model="produto.custo"
                  v-money="monetario"
                  dense
                  regular
                  label="Custo"
                  :value="produto.custo"
                  reverse
                />
              </v-col>

              <v-col
                sm="6"
                :md="$vssWidth <= 1200 ? 6 : 3"
                cols="12"
              >
                <v-text-field
                  v-model="produto.venda"
                  v-money="monetario"
                  dense
                  regular
                  label="Venda"
                  :value="produto.venda"
                  reverse
                />
              </v-col>
              <v-col
                sm="12"
                :md="$vssWidth <= 1200 ? 12 : 6"
                cols="12"
                class="d-flex justify-center mt-4"
              >
                <v-btn
                  id="save"
                  color="primary"
                  depressed
                  :href="valid ? '' : '#importacao-produtos'"
                  @click="salvar"
                >
                  <v-icon> save </v-icon>
                  Salvar
                </v-btn>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-col>
    </v-form>
  </v-card>
  <v-card
    v-else
    flat
    :style="{
      border: $vuetify.theme.dark
        ? '1px solid #555 !important'
        : '1px solid #ddd !important',
      height: '688px',
      overflowY: 'auto',
    }"
  >
    <v-card-text :style="{ height: '100%' }">
      <div
        class="d-flex flex-column justify-end align-center"
        :style="{ height: '20%' }"
      >
        <span
          :style="{ fontSize: '1.5rem' }"
        >Selecione o produto para editar</span>
      </div>
      <div
        class="d-flex flex-column justify-center align-center"
        :style="{ height: '80%' }"
      >
        <img
          src="/select.svg"
          :style="{ width: '60%' }"
        >
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
const vazio = [{}];
export default {

  props: {
    produtoSelecionado: {
      type: Object,
      required: true,
      default: () => {
        return {};
      },
    },
  },

  data() {
    return {
      produto: this.produtoSelecionado.prod,
      valid: true,
      monetario: {
        decimal: ',',
        thousands: '.',
        prefix: '',
        suffix: '',
        precision: 2,
        masked: false,
      },
      rules: {
        required: (value) => !!value || "Esse campo precisa ser preenchido.",
        barras: [
          (value) => !!value || "Esse campo precisa ser preenchido.", // Verifica se está preenchido
          (value) => (value && String(value).length >= 5) || "Tamanho inválido. Deve ter no mínimo 5 dígitos.",
        ],
        baraux: [
          (value) =>
            !value || String(value).length >= 5 || "Tamanho inválido. Deve ter no mínimo 5 dígitos.",
        ],
        sku: [(value) => (!!value && String(value).length > 0) || "SKU inválido"],
        ref: [
          (value) => (!!value && String(value).length > 0) || "Referência inválida",
        ],
        desc: [
          (value) => (!!value && String(value).length > 0) || "Descrição inválida",
        ],
      },
      attKey: 0,
      grupo: "",
      subgrupo: "",
      depto: "",
      secao: "",
      colec: "",
      categ: "",
      marca: "",
      fator: "",
      mf: "",
      unidade: [],
      ncm: [],
      attImg: 0,
      tp_otico: [
        {id: 1, name: "Lente"},
        {id: 2, name: "Armação"},
        {id: 3, name: "Acessórios"},
        {id: 4, name: "Tratamento"},
      ],
    };
  },

  watch: {
  },

  async created() {
    try {
      let retorno = await this.request({
        metodo: 'get',
        sp: "SP_CE360901045",
        params: vazio,
      });
      retorno = retorno.database;
      let lUnidade = [];
      let lNCM = [];
      retorno.forEach((v) => {
        if (v.tipo == 'N') {
          lUnidade.push({
            nf722_nr_uncom: v.nf722_nr_uncom,
            nf722_cd_uncom: v.nf722_cd_uncom,
            nf722_ds_uncom: v.nf722_ds_uncom,
          });          
        }
        if (v.tipo == 'U') {
          lNCM.push({
            ce311_cd_ncm: v.ce311_cd_ncm,
            ce311_cd_exncm: v.ce311_cd_exncm,
          });          
        }
      });
      this.unidade = lUnidade;
      this.ncm = lNCM;
    } catch (e) {
      this.mensagemConsole('ERRO', e);
    }
  },

  beforeUpdate () {
    if (this.produtoSelecionado.prod != null && this.produtoSelecionado.prod != undefined && Object.keys(this.produtoSelecionado.prod)){

      this.grupo = `${this.produto.cd_grp ? this.produto.cd_grp : '*'} - ${this.produto.ds_grp ? this.produto.ds_grp : '*'}`;
      this.subgrupo = `${this.produto.cd_subgrupo ? this.produto.cd_subgrupo : '*'} - ${this.produto.ds_subgrupo ? this.produto.ds_subgrupo : '*'}`;
      this.depto = `${this.produto.cd_depto ? this.produto.cd_depto : '*'} - ${this.produto.ds_depto ? this.produto.ds_depto : '*'}`;
      this.secao = `${this.produto.cd_secao ? this.produto.cd_secao : '*'} - ${this.produto.ds_secao ? this.produto.ds_secao : '*'}`;
      this.colec = `${this.produto.cd_colec ? this.produto.cd_colec : '*'} - ${this.produto.ds_colec ? this.produto.ds_colec : '*'}`;
      this.categ = `${this.produto.cd_categ ? this.produto.cd_categ : '*'} - ${this.produto.ds_categ ? this.produto.ds_categ : '*'}`;
      this.marca = `${this.produto.cd_marca ? this.produto.cd_marca : '*'} - ${this.produto.ds_marca ? this.produto.ds_marca : '*'}`;
      this.fator = this.produto.fator;
      this.mf = this.produto.mf;
    }
    
  },

  updated () {
    this.$refs.form.validate();
  },

  methods: {

    salvar() {
      if (this.$refs.form.validate()) {
        this.produto = this.validaForm(this.produto);
        this.$emit("produto", {
          id: this.produtoSelecionado.id,
          prod: this.produto,
        });
      } 
    },

    validaForm(prod) {
      let itensProduto = prod;
      itensProduto = this.prodUpperCase(itensProduto);
      return itensProduto;
    },

    prodUpperCase(items) {
      let campos = items;
      campos.barras = campos.barras.toString().trim();
      campos.baraux = campos.baraux.toString().trim();
      campos.sku = this.trimUpper(campos.sku);
      campos.ref = this.trimUpper(campos.ref);
      campos.prod = this.trimUpper(campos.prod);
      return campos;
    },

    trimUpper(val) {
      let campo = val;
      return campo.toString().trim().toUpperCase();
    },
    
  },
};
</script>

<style>
.dividerSpan {
  display: flex;
  font-weight: bold;
}
.dividerSpan::before {
  content: "";
  flex: 1;
}
.dividerSpan::after {
  content: "";
  flex: 1;
}
.line {
  align-items: center;
  margin: 1em -1em;
}
.line::before,
.line::after {
  height: 1px;
  margin: 0 1em;
}
.glow::before,
.glow::after {
  height: 3px;
  border-radius: 5px;
}
.glow::before {
  background: linear-gradient(
    to right,
    var(--v-primary-base),
    var(--v-primary-lighten3)
  );
}
.glow::after {
  background: linear-gradient(
    to left,
    var(--v-primary-base),
    var(--v-primary-lighten3)
  );
}
</style>